<template>
  <div class="eventRewardsPage">
    <div class="tabTitle">
      <van-loading v-if="tabLoading" type="spinner" color="#FF006E" vertical>加载中...</van-loading>
      <div class="eventRewards_list" v-if="!tabLoading">
        <van-list v-model="moreLoading" :finished="finished" offset="50" finished-text="没有更多了" @load="upMoreLoad"
          v-if="eventRewardsList && eventRewardsList.length > 0">
          <div class="item_box" v-for="(item, index) in eventRewardsList" :key="index">
            <!-- <div class="order_type">活动奖励</div> -->
            <div class="product_activity_award">
              <b>{{ item.activityName }}</b>
            </div>
            <div class="order_info">
              <div class="left">
                <span class="order_createDate">创建时间：{{ item.createTime }}</span>
                <div class="row">
                  <span class="money">奖励金：<span>¥{{ item.commissionAmount }}</span><span>（活动奖励）</span></span>
                  <div class="right" style="color: #FF006E;" v-if="item.commissionStatus == 1">待结算</div>
                  <div class="right" v-if="item.commissionStatus == 2">已结算</div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <div class="noData" v-if="eventRewardsList.length === 0"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { getToken } from '@/utils/tokenUtils'
import './index.less'
import { GetUserActivityPageList } from '@/service/withdraw'
export default {
  data() {
    return {
      mid: getToken('mid'),
      mgUuid: getToken('mgUuid'),
      tabLoading: false,
      moreLoading: false,
      finished: false,
      eventRewardsList: [], // 活动奖励列表数据
      pageCurrent: 1,
      pageSize: 10
    }
  },
  computed: {},
  created() {
    setTimeout(() => {
      // 埋点，会员数据上报（页面PV）
      const token = localStorage.getItem('token')
      const mgUuid = token ? this.mgUuid : ''
    }, 200)
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.upMoreLoad()
    },
    // 活动奖励列表
    getUserActivityPageList() {
      const idx = this.pageCurrent
      const params = {
        pageIndex: this.pageCurrent,
        pageSize: this.pageSize
      }
      GetUserActivityPageList(params).then((res) => {
        const { code, data, message } = res
        if (code === '0') {
          this.tabLoading = false
          this.moreLoading = false
          if (idx > 1) {
            this.eventRewardsList = [...this.eventRewardsList, ...data.list]
          } else {
            this.eventRewardsList = data.list
          }
          if (data && this.eventRewardsList.length == data.total) {
            this.finished = true
          } else {
            this.finished = false
          }
          // console.log('this.eventRewardsList', this.eventRewardsList)
        } else {
          this.tabLoading = false
          this.$toast(message)
        }
      })
    },
    // 上滑加载更多
    upMoreLoad() {
      this.moreLoading = true
      this.getUserActivityPageList()
      this.pageCurrent += 1
    }
  }
}
</script>
<style lang="less" scoped></style>
